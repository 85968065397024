import React from "react";
import Form from "../screens/Form2";

const Layout = () => {
    return (
        <div>
            <Form />
        </div>
    );
};

export default Layout;
