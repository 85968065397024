import React from "react";
import Layout from "./main/layout/Layout";

const App = () => {
    return (
        <div>
            <Layout />
        </div>
    );
};

export default App;
